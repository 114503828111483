import * as actionTypes from "./actionTypes";

import axios from "axios";

let hasRun = [];

const setExhibitorBooths = (payload) => ({
  type: actionTypes.SET_EXHIBITOR_BOOTHS,
  payload,
});

export const dataTypes = {
  exhibitorBooths: "virtual-booths",
};

export const getPayload = (payload) => (dispatch) => {
  if (!hasRun.includes(payload)) {
    hasRun.push(payload);

    axios
      .get(`${process.env.REACT_APP_API_HOST}/${payload}`)
      .then((response) => response.data)
      .then((data) => {
        switch (payload) {
          case dataTypes.exhibitorBooths:
            console.log("ran");
            dispatch(setExhibitorBooths(data));
            break;
          default:
            break;
        }
      });
  }
};
