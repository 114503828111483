import "./css/style.scss";

import { Route, Switch } from "react-router";
import { dataTypes, getPayload } from "store/actions/index";

import { BrowserRouter } from "react-router-dom";
import Matrix from "./Components/Matrix/Matrix";
import React from "react";
import VirtualBoothPreview from "Components/Exhibitor/VirtualBoothPreview";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  dispatch(getPayload(dataTypes.exhibitorBooths));
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/matrix" component={Matrix} />
        <Route
          exact
          path="/exhibitor/:exhibitorID"
          component={VirtualBoothPreview}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
